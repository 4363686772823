import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams  } from "react-router-dom";
import config from '../../../../config';
import useDebounce from './useDebounce';
import axios from "axios";
import Cleave from 'cleave.js/react';
import MaskedInput from 'react-text-mask';
import Layout from "./Layout";
import { MerchantStateContext } from '../context/MerchantStateContext';
import MessagePopover from './Popover';
import ServerError from "./ServerError";

import { returnTrueIsSpanishIsPrefered , translateIfPreferedLanguageIsSpanish } from '../../../../lib/util';


export default function PersonalInformation(props) {
	const [loading, setLoading] = useState(false);
	const [disableButton, setDisableButton] = useState('')
	const [showPass, setShowPass] = useState({ssn: '', taxId: ''});
	const [formError, setFormError] = useState({});
	const [serverError, setServerError] = useState(false);
	const { setMainState, mainState, setData, data, handleChange, gtm_DataLayer } = useContext(MerchantStateContext);
	const { contact__address__address1, contact__address__address2, contact__address__city, contact__address__state, contact__address__zip, contact__birthDate, contact__socialSecurityNumber, account__taxId } = mainState;



	const history = useHistory();
	const params = useParams();
	const clearHistoryAndPush = (path) => {
		history.entries = [];
		history.index = -1;
		history.push(path);
	};

	useEffect(() => {

		// correct data if we have the initial data stored
		if((data.accountUuid === undefined || !data.accountUuid) && mainState.account__uuid) data.accountUuid = mainState.account__uuid;
		if((data.contactUuid === undefined || !data.contactUuid) && mainState.contact__uuid) data.contactUuid = mainState.contact__uuid;
		if((data.opportunityUuid === undefined || !data.opportunityUuid) && mainState.opportunity__uuid) data.opportunityUuid = mainState.opportunity__uuid;
		setData({...data});
		sessionStorage.setItem("data", JSON.stringify({...data}));

		let sessionData = sessionStorage['data']? JSON.parse(sessionStorage.getItem("data")):'';
		if (!sessionData.app && params.accountUuid && params.contactUuid && params.opportunityUuid){
			if((params.accountUuid === undefined || !params.accountUuid) && data.accountUuid) params.accountUuid = data.accountUuid;
			if((params.contactUuid === undefined || !params.contactUuid) && data.contactUuid) params.contactUuid = data.contactUuid;
			if((params.opportunityUuid === undefined || !params.opportunityUuid) && data.opportunityUuid) params.opportunityUuid = data.opportunityUuid;
			if(sessionData && mainState.opportunity__uuid && mainState.contact__uuid && mainState.account__uuid)
			{
				setData({...data, accountUuid: mainState.account__uuid, contactUuid: mainState.contact__uid, opportunityUuid: mainState.opportunity__uuid })
                sessionStorage.setItem("data", JSON.stringify({ ...data, accountUuid: mainState.account__uuid, contactUuid: mainState.contact__uid, opportunityUuid: mainState.opportunity__uuid }));
			}

			setLoading(true);
			let url = `${config.apiUrl}/api/uplyftOnboarding/${params.accountUuid}/accountValidate/${params.contactUuid}/${params.opportunityUuid}`
				axios.get(url)
				.then((res) => setLoading(false) )
				.catch( error => {
						clearHistoryAndPush('/404');
						console.error(error);
					});
		};
	},[]);

	useEffect(() => {
		if(sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			setMainState({
				...tempState
                , contact__address__address1: tempState.contact__address__address1
                , contact__address__address2: tempState.contact__address__address2
                , contact__address__city: tempState.contact__address__city
                , contact__address__state: tempState.contact__address__state
                , contact__address__zip: tempState.contact__address__zip
                , contact__birthDate: tempState.contact__birthDate ? tempState.contact__birthDate : ''
                , contact__socialSecurityNumber: tempState.contact__socialSecurityNumber ? tempState.contact__socialSecurityNumber : ''
                , account__taxId: tempState.account__taxId ? tempState.account__taxId : ''
            });
		};

		if(!sessionStorage['data'] ) clearHistoryAndPush('/404');

	},[]);
	let langPref = mainState.contact__preferred__language
	const popoverMessage ={
		title: translateIfPreferedLanguageIsSpanish(langPref,'Why Do We Ask for SS#?', '¿Por qué solicitamos SS#?') ,
		body: translateIfPreferedLanguageIsSpanish(langPref,'We must verify the identity of each individual who directly or indirectly owns 25% or more of the equity interests of the business. We also must verify the identity of one individual who has significant responsibility for managing the business.', 'Debemos verificar la identidad de cada individuo que posee directa o indirectamente el 25% o más de las participaciones accionarias de la empresa. También debemos verificar la identidad de una persona que tenga una responsabilidad importante en la gestión del negocio.') ,
		comments: [
			translateIfPreferedLanguageIsSpanish(langPref,'No Hard Inquiries', 'Sin consultas crediticias') ,
			,
			translateIfPreferedLanguageIsSpanish(langPref,'No Impact On Your Credit', 'Sin impacto en su crédito') ,
		]

	};

	const dots = [
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Signature", status: 0 },
		{ name: "Upload", status: 0 }
	];

	const dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 1 },
		{ name: "Firma", status: 0 },
		{ name: "Documentos", status: 0 }
	];

	const formValidation = (e) => {
		let form = e.target
		let isValid = form.checkValidity()
		let formData = new FormData(form)
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage;
			return acc;
		}, {})

		setFormError(validationMessages);
		return isValid;
	}

	const onInput = (e) => {
		const input = e.target
		setFormError({ ...formError, [input.name]: input.validationMessage })
	}

	const getError = (field) => formError[field];

	const clickSubmit = e => {
		e.preventDefault();
		let today = new Date();
		let contactBirthDate = new Date(contact__birthDate);
		let isValid = formValidation(e);

		if(contact__birthDate !== "" && contactBirthDate > today){setFormError({ ...formError, 'contact__birthDate': 'Please enter valid date'}); isValid = false}
		if(parseInt(contact__birthDate.substring(6,10)) < 1900){ setFormError({ ...formError, 'contact__birthDate': 'Date can\'t be before 1900'}); isValid = false}

		if(socialSecurityValidation(contact__socialSecurityNumber)){ setFormError({...formError, 'contact__socialSecurityNumber': socialSecurityValidation(contact__socialSecurityNumber)}); isValid = false}
		if(employerIdentificationValidation(account__taxId)){ setFormError({ ...formError, 'account__taxId': employerIdentificationValidation(account__taxId)}); isValid = false}

		if (isValid){
			if (data.isLocalHostIP ){
				sessionStorage.clear();
				axios.post(`${config.apiUrl}/api/uplyftOnboarding/updateApplication`, { mainState, data })
				.then(res => {
					if(res.data.status === 1) history.push('/onboarding/merchant/finished/')
				})
				.catch(error => {
					console.log('error', error)
				})
			} else {

				let returningUser = data.returningUser || false;
				
				if(returningUser){
					console.log('After personal Info we ignore GTM because is returning user, ', returningUser)
					sendPostRequest();
				}else{
					console.log('we are start the GTM after personal info because is a new user ', returningUser)
					gtm_DataLayer(0.05, 0.08);
					sendPostRequest();
					// debounceSubmit();
				}
			
			};
		}
	};

	const socialSecurityValidation = (socialSecurityNumber) => {
		// SS# cannot contain these substrings
		const invalidSubstrings = ["000", "666", "00", "0000"]
		// Known fake SS#
		const dummyCases = ["078051120", "111111111", "123456789", "219099999", "333333333", "666666666", "999999999"]
		// Remove SS# formatting for validation
		const tempSSN = socialSecurityNumber.replaceAll("-", "")
		// Breaking up SS# for readability
		const areaNumberSSN = tempSSN.substring(0, 3)
		const groupNumberSSN = tempSSN.substring(3, 5)
		const serialNumberSSN = tempSSN.substring(5)

		// Length/Numeric checks done automatically in form input
		// Check substrings + out of bounds area values
		if (invalidSubstrings.includes(areaNumberSSN) ||
			invalidSubstrings.includes(groupNumberSSN) ||
			invalidSubstrings.includes(serialNumberSSN)){
			return "The SS# is invalid"
		}

		// Check if known fake SS#
		if (dummyCases.includes(tempSSN) || (Number(tempSSN) < 987654329 && Number(tempSSN) > 987654320)){
			return "The SS# is invalid"
		}

		return false
	}

	const employerIdentificationValidation = (employerIdentificationNumber) => {
		// Employer Identification Numbers cannot begin with any of these numbers
		const invalidPrefixes = ["07","08", "09", "17", "18", "19", "28", "29", "49", "69", "70", "78", "79", "89", "96", "97"];

		if (invalidPrefixes.includes(employerIdentificationNumber.substring(0,2))){
			return "The Federal Tax Id is invalid";
		}
		const tempEIN = employerIdentificationNumber.replaceAll("-", "");
		const dummyEINs = ["000000000","111111111", "222222222", "333333333", "444444444", "555555555", "666666666",
						"777777777", "888888888", "999999999", "987654321", "123456789", "098765432"];

		if (dummyEINs.includes(tempEIN)) return "The Federal Tax Id is invalid";

		return false;
	}

	// const debounceSubmit = useDebounce(() => sendPostRequest() ,500) ;
	const sendPostRequest = () => {
		axios.post(`${config.apiUrl}/api/uplyftOnboarding/index`, { mainState, data })
			.then((res) => {
				if(res.data.duplicate) {
					console.error(res.data.duplicate); // see reason for debugging/dev
					let curDuplicateType = res.data.duplicate;
					if(curDuplicateType === 'phone') curDuplicateType += ' number';
					if(curDuplicateType === 'name') curDuplicateType = 'business name';
					if(curDuplicateType === 'noneditable' || curDuplicateType === 'esign') setServerError({message:<> Your application has already been submitted. <br/>If you need to make changes or have received this message in error, please contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></> })
					else setServerError({message:<> An application has recently been submitted with that <b>{curDuplicateType}</b>. <br/><br/>If you need to make changes or have received this message in error, contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></>})
				}
				else if (res.data.account && res.data.contact && res.data.opportunity)
				{
					setMainState({...mainState, contact__birthDate: '', contact__socialSecurityNumber: '', account__taxId: '' });
					sessionStorage.setItem("mainState", JSON.stringify(mainState));
					history.push(`/onboarding/merchant/${res.data.account.uuid}/esign/${res.data.contact.uuid}/${res.data.opportunity.uuid}`);
				}
			})
			.catch(error => {
				console.error('error', error);
			});
	 };

	const personalInformationForm = () => (
		<div id='personalInfo' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Home Address', 'Direccion de casa')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("contact__address__address1")}
						type='text'
						name='contact__address__address1'
						className='form-control form-elegant text-muted'
						placeholder='100 Sunnyville Road'
						value={contact__address__address1}
						required
					/>
					<small className='text-danger'>{getError('contact__address__address1')}</small>
				</div>
				<div className='form-group col-12 col-lg-6 pr-0'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Address 2 - Optional', 'Direccion 2 - Opcional')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("contact__address__address2")}
						type='text'
						name='contact__address__address2'
						className='form-control form-elegant text-muted'
						placeholder='Apt #'
						value={contact__address__address2}
					/>
					<small className='text-danger'>{getError('contact__address__address2')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-6 col-lg-4 col-lg-4'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'City', 'Ciudad')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("contact__address__city")}
						type='text'
						name='contact__address__city'
						className='form-control form-elegant text-muted'
						placeholder='Miami'
						value={contact__address__city}
						required
					/>
					<small className='text-danger'>{getError('contact__address__city')}</small>
				</div>

				<div className='form-group col-6 col-lg-4'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'State', 'Estado')}</label>
					<select
						onChange={onInput}
						onInput={handleChange("contact__address__state")}
						name='contact__address__state'
						value={contact__address__state}
						className='form-control form-elegant text-muted'
						required
					>
						<option value='' defaultValue className='text-muted'>
							{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Select Your State', 'Seleccione su estado')}
						</option>
						{config.usStatesList.map((state, i) => (
							<option key={i} value={state.abbreviation}>{state.name}-{state.abbreviation}</option>
						))}
					</select>
					<small className='text-danger'>{getError('contact__address__state')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Zip Code', 'Código postal')}</label>
					<MaskedInput
						// onInput={onInput}
						onChange={handleChange("contact__address__zip")}
						// options={{ numericOnly: true, blocks: [5] }}
						mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
						guide={true}
						type='text'
						name='contact__address__zip'
						className='form-control form-elegant text-muted'
						placeholder='00000'
						pattern='^\d{5}$'
						value={contact__address__zip}
						required
					/>
					<small className='text-danger'>{getError('contact__address__zip')}</small>
				</div>

				<div className='form-group col-6 col-lg-4'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Birthday', 'Fecha de nacimiento')}</label>
					<Cleave
						onInput={onInput}
						onChange={handleChange("contact__birthDate")}
						options={{ date: true, delimiter: '/', datePattern: ['m','d','Y'] }}
						name='contact__birthDate'
						className='form-control form-elegant text-muted'
						placeholder='mm / dd / yyyy'
						value={contact__birthDate}
						required
					/>
					<small className='text-danger'>{getError('contact__birthDate')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>
					<label className=''><MessagePopover message={popoverMessage} />{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'SS#', 'SS#')}</label>
					<MaskedInput
						// onInput={onInput}
						onChange={handleChange("contact__socialSecurityNumber")}
						onBlur={() => setShowPass({...showPass,  ssn: 'secure_input'}) }
						onFocus={() => setShowPass({ ...showPass, ssn: ''}) }
						// options={{ numericOnly: true, delimiter: '-', blocks: [3, 2, 4]}}
						mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
						guide={true}
						type= 'text' //{showPass.ssn}
						name='contact__socialSecurityNumber'
						className={`form-control form-elegant text-muted ${showPass.ssn}`}
						placeholder='xxx-xx-xxxx'
						pattern='^\d{3}-\d{2}-\d{4}$'
						// minLength='11'
						value={contact__socialSecurityNumber}
						required
					/>
					<small className='text-danger'>{getError('contact__socialSecurityNumber')}</small>
				</div>
				<div className='form-group col-6 col-lg-4'>
					<label className=''>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Federal Tax Id', 'ID de impuestos federales')}</label>
					<MaskedInput
						// onInput={onInput}
						onChange={handleChange("account__taxId")}
						onBlur={() => setShowPass({...showPass,  taxId: 'secure_input'}) }
						onFocus={() => setShowPass({ ...showPass, taxId: ''}) }
						mask={[/[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
						guide={true}
						type= 'text'// {showPass.taxId}
						name='account__taxId'
						className={`form-control form-elegant text-muted ${showPass.taxId}`}
						placeholder='00-0000000'
						pattern='^\d{2}-\d{7}$'
						value={account__taxId}
						required
					/>
					<small className='text-danger'>{getError('account__taxId')}</small>
				</div>
			</div>
		</div>
	);

	return (
		serverError ? <ServerError error={serverError} /> :
		<>
			{ loading && <div className='spinner'><div className='loader'>submitting...</div></div> }
			<div style={ loading ? {position: 'relative', opacity: 0.3, zIndex: -10} : {} }>
				<Layout
					title={translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Last few details', 'Últimos detalles')}
					preRouteText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Back', 'Atras')}
					btnContinueText= {data.isLocalHostIP ?  translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Save', 'Guardar') : translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Continue', 'Continuar')}
					htmlTitle='Personal-Info'
					preRoute='personal'
					showPreButton='visible'
					preRouteClass='btn btn-light'
					className=''
					invokeFunction= {clickSubmit }
					btnContinueId = 'personalInfoCBtn'
					disabled={disableButton }
					dots = {returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language) ? dotsInSpanish :dots }
				>
					{personalInformationForm()}
				</Layout>
			</div>
		</>
	);
}
