import React, { useState, useEffect, useContext } from "react";
import { useHistory} from "react-router-dom";
import config from '../../../../config';
import axios from 'axios';
import Layout from "./Layout";
import useDebounce from './useDebounce';
import MaskedInput from 'react-text-mask'
import { MerchantStateContext } from '../context/MerchantStateContext';
import ServerError from "./ServerError";

import { returnTrueIsSpanishIsPrefered, translateIfPreferedLanguageIsSpanish } from '../../../../lib/util';


export default function Personal(props) {
	const [disableButton, setDisableButton] = useState('');
	const [formError, setFormError] = useState({});
	const [serverError, setServerError] = useState(false);
	const { setMainState, mainState, setData, data, handleChange , gtm_DataLayer, validatePhoneNumber, validateEmail} = useContext(MerchantStateContext);
	const { contact__firstName, contact__lastName, contact__email, contact__mobilePhone } = mainState;
	const [AlreadyValidatedPhone, setAlreadyValidatedPhone] = useState(false);
	const [AlreadyValidatedEmail, setAlreadyValidatedEmail] = useState(false);

	const history = useHistory();

	useEffect(() => {
		// Prevent from merchants to land directly on the page w/out filling the previous pages
		if (!sessionStorage['mainState']) history.push('/onboarding/merchant/');
		//persist state => pull state from session storage everything the page reload
		if (sessionStorage['mainState'] ){
			let tempState = JSON.parse(sessionStorage.getItem("mainState"));
			// incase of attempt to "jump" pages using urls in favorite or saved link etc, without going through the form workflow
			// inside the "if statement" we are 'sampling' one property from each previous component to see if exist, if one is missing,
			// we send the merchant to the beginning of the app
			if (!tempState.opportunity__requestedAmount || !tempState.account__name || !tempState.account__workPhone) history.push('/onboarding/merchant/');
			setMainState({
				...tempState
					, contact__firstName: tempState.contact__firstName
					, contact__lastName: tempState.contact__lastName
					, contact__email: tempState.contact__email
					, contact__mobilePhone: tempState.contact__mobilePhone
			});
		};
	}, []);

	let langPref = mainState.contact__preferred__language

	const dots = [
		{ name: "Funding", status: 1 },
		{ name: "Business", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 0 },
		{ name: "Signature", status: 0 },
		{ name: "Upload", status: 0 }
	];

	const dotsInSpanish = [
		{ name: "Financiamiento", status: 1 },
		{ name: "Negocio", status: 1 },
		{ name: "", status: 1 },
		{ name: "Personal", status: 1 },
		{ name: "", status: 0 },
		{ name: "Firma", status: 0 },
		{ name: "Documentos", status: 0 }
	];
	const optInoptOutDisclaimer =<p>By opting in, I agree to the <a href="https://www.uplyftcapital.com/terms-of-use" target="_blank">terms and conditions</a> and to Uplyft Capital using my contact details to get in touch. No mobile information will be shared with third parties/affiliates for marketing/promotional purposes. Reply HELP for assistance or STOP to opt-out. Data rates may apply. Frequency varies.</p>

	const dncDisclaimer = {
		english:'*We take your privacy seriously. By providing your mobile phone number and email, you are providing express "written" consent for Uplyft Capital and it\'s representatives to contact you via telephone, mobile device and/or email even if your telephone number is currently listed on any internal, corporate, state, federal or national Do-Not-Call (DNC) list.'
		,
		spanish:'*Nos tomamos en serio su privacidad. Al proporcionar su número de teléfono móvil y correo electrónico, usted brinda su consentimiento expreso "por escrito" para que Uplyft Capital y sus representantes se comuniquen con usted por teléfono, dispositivo móvil y/o correo electrónico, incluso si su número de teléfono figura actualmente en cualquier información interna, lista corporativa, estatal, federal o nacional de No llamar (DNC).'
	}

	const validationErrors = {
		email:{
			english:'Make sure you are providing a valid email address.'
			,spanish:'Asegúrese de proporcionar una dirección de correo electrónico válida.'
		},
		mobileNumber:{
			english:'Providing a valid cell phone number increases the likelihood of getting funded.'
			,spanish:'Proporcionar un número de teléfono móvil válido aumenta la probabilidad de recibir financiación.'
		}

	}

	const formValidation = (e) => {
		let form = e.target;
		let isValid = form.checkValidity();
		let formData = new FormData(form);
		let validationMessages = Array.from(formData.keys()).reduce((acc, key) => {
			acc[key] = form.elements[key].validationMessage;
			return acc
		}, {});

		setFormError(validationMessages);
		return isValid;
	}

	const onInput = (e) => {
		const input = e.target;
		setFormError({ ...formError, [input.name]: input.validationMessage });
	}

	const getError = (field) => formError[field]

	const clickSubmit = async e => {
		e.preventDefault();
		// setDisableButton('disabled');
		let isValid = formValidation(e);

		let validationErrors = {};
		if (!AlreadyValidatedEmail) {
			if(!await validateEmail(contact__email)){
				// validationErrors.contact__email = "Make sure you are providing a valid email address.";

				validationErrors.contact__email = translateIfPreferedLanguageIsSpanish( langPref, 'Me sure you are providing a valid email address.','Asegúrese de proporcionar una dirección de correo electrónico válida.');
				isValid = false;
				// Not having a validated email does not prevent the merchant from continuing.
			}
			setAlreadyValidatedEmail(true);
		}

		if (!AlreadyValidatedPhone) {
			if(!await validatePhoneNumber(contact__mobilePhone.replace(/\D/g, ""))) {
				validationErrors.contact__mobilePhone =  translateIfPreferedLanguageIsSpanish( langPref, 'Providing a valid cell phone number increases the likelihood of getting funded.','Proporcionar un número de teléfono móvil válido aumenta la probabilidad de recibir financiación.');;
				isValid = false;
				// Not having a validated/wireless number does not prevent the merchant from continuing.
			}
			setAlreadyValidatedPhone(true);
		}
		if (validationErrors.length !== 0) setFormError({...formError, ...validationErrors});

		// if(!isValid) {
		// 	setDisableButton('');
		// 	return;
		// };

		if(isValid){
			// correct if we have the initial data stored
			if((data.accountUuid === undefined || !data.accountUuid) && mainState.account__uuid) data.accountUuid = mainState.account__uuid;
			if((data.contactUuid === undefined || !data.contactUuid) && mainState.contact__uuid) data.contactUuid = mainState.contact__uuid;
			if((data.opportunityUuid === undefined || !data.opportunityUuid) && mainState.opportunity__uuid) data.opportunityUuid = mainState.opportunity__uuid;
			//setData({...data});
			//sessionStorage.setItem("data", JSON.stringify({...data}));

			if(data.isLocalHostIP){
				sessionStorage.setItem("mainState", JSON.stringify(mainState));
				history.push(`/onboarding/merchant/${data.accountUuid}/personalInfo/${data.contactUuid}/${data.opportunityUuid}`);
			} else {
				let returningUser = data.returningUser || false;
				
				if(returningUser){
					console.log('we are not going to log anything because returning user: ', returningUser)
					sendPostRequest();
				}else{
					console.log('we are start the GTM because is a new user: ', returningUser)
					gtm_DataLayer(0.025, 0.08);
					sendPostRequest();
					// debounceSubmit();
				}

			};
		}
	};

	// const debounceSubmit = useDebounce(() => sendPostRequest() ,500) ;
	const sendPostRequest = () => {
       axios.post(`${config.apiUrl}/api/uplyftOnboarding/index`, { mainState, data })
            .then(res => {

				if(res.data)
				{
					if(res.data.duplicate) {
						console.error(res.data.duplicate); // see reason for debugging/dev
						let curDuplicateType = res.data.duplicate;
						if(curDuplicateType === 'phone') curDuplicateType += ' number';
						if(curDuplicateType === 'name') curDuplicateType = 'business name';
						if(curDuplicateType === 'noneditable' || curDuplicateType === 'esign')	setServerError({message:<> Your application has already been submitted. <br/>If you need to make changes or have received this message in error, please contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></> })
						else setServerError({message:<> An application has recently been submitted with that <b>{curDuplicateType}</b>. <br/><br/>If you need to make changes or have received this message in error, contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></>})
					}
					else if (res.data.account && res.data.contact && res.data.opportunity)
					{
						if(res.data.opportunity.uuid === undefined && data.opportunityUuid) res.data.opportunity.uuid = data.opportunityUuid;
						if(res.data.account.uuid === undefined && data.accountUuid) res.data.account.uuid = data.accountUuid;
						if(res.data.contact.uuid === undefined && data.contactUuid) res.data.contact.uuid = data.contactUuid;
						const {account, contact, opportunity} = res.data;
						saveAccountData( account, contact, opportunity);
						sessionStorage.setItem("mainState", JSON.stringify(mainState));
						history.push(`/onboarding/merchant/${res.data.account.uuid}/personalInfo/${res.data.contact.uuid}/${res.data.opportunity.uuid}`);
					}
					else // display error type somehow
					{
						setServerError({message:<> An unknown error occurred! <br/>If you need to make changes or have received this message in error, please contact one of our funding advisors at the email below.<br/><br/><a href="mailto:sales@uplyftcapital.com"><p style={{color:"#6b1cf5"}}>Click here to email us: sales@uplyftcapital.com</p></a></> })
					}
				}
			})
            .catch(error => {
                console.error('error', error);
            });
	};

	const saveAccountData = (account, contact, opportunity ) => {
        let accountData = {
            accountUuid:   account.uuid || ''
            , contactUuid:  contact.uuid || ''
            , opportunityUuid:  opportunity.uuid || ''
            , campaign: account.campaign || ''
            , campaignVariation: account.campaignVariation || ''
			, campaignRecordId: account.campaignRecordId || ''
            , contactName:  contact.firstName || ''
            , contactEmail:  contact.email || ''
            , isLocalHostIP: opportunity.isLocalHostIP || false
            // , isLocalHostIP: false //testing only
            , referrer: opportunity.referrer || ''
            , r: opportunity.r || data.r || ''
            , returningUser: data.returningUser,
        };
        setData({...data, ...accountData })
        sessionStorage.setItem("data", JSON.stringify(accountData));
    };

	const personalForm = () => (
		<div id='personal' className='p-2'>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'First Name', 'Nombre')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("contact__firstName")}
						type='text'
						name='contact__firstName'
						className='form-control form-elegant text-muted'
						placeholder='First'
						value={contact__firstName}
						pattern="^\s*[a-zA-Z]\s*(?:\S\s*){1,}"
						required
					/>
					<small className='text-danger'>{getError('contact__firstName')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Last Name', 'Apellido')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("contact__lastName")}
						type='text'
						name='contact__lastName'
						className='form-control form-elegant text-muted'
						placeholder='Last'
						value={contact__lastName}
						pattern="^\s*[a-zA-Z]\s*(?:\S\s*){1,}"
						required
					/>
					<small className='text-danger'>{getError('contact__lastName')}</small>
				</div>
			</div>
			<div className='row'>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Email', 'Correo electrónico')}</label>
					<input
						onInput={onInput}
						onChange={handleChange("contact__email")}
						type='email'
						name='contact__email'
						className='form-control form-elegant text-muted'
						placeholder='owner@ourbusiness.com'
						pattern='[a-z0-9!#$%&*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?' title='Please match the requested pattern: owner@ourbusiness.com'
						value={contact__email}
						required
					/>
					<small className='text-danger'>{getError('contact__email')}</small>
				</div>
				<div className='form-group col-12 col-lg-6'>
					<label className='pb-2'>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Cell Phone', 'Teléfono móvil')}</label>
					<MaskedInput
						// onInput={onInput}
						onChange={handleChange("contact__mobilePhone")}
						name='contact__mobilePhone'
						// options={{ numericOnly: true, delimiters: ['(', ') ', '-'], blocks: [0, 3, 3, 4] }}
						mask={['(', /[2-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
						guide={true}
						keepCharPositions={true}
						className='form-control form-elegant text-muted'
						pattern='((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}' title='Please match the requested pattern: (555) 555 -5555'
						placeholder='(555) 555-5555'
						value={contact__mobilePhone}
						required
					/>
					<small className='text-danger'>{getError('contact__mobilePhone')}</small>
				</div>
				<p className="h6 mt-4 ml-2 mr-2 font-weight-light" style={{fontSize:"11px"}}>{translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language, dncDisclaimer.english , dncDisclaimer.spanish )}{optInoptOutDisclaimer}</p>
			</div>
		</div>
	);

	return (
		serverError ? <ServerError error={serverError} /> :
		<Layout
			title={translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Tell us about you', 'Cuéntanos acerca de ti')}
			preRouteText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Back', 'Atras')}
			btnContinueText = {translateIfPreferedLanguageIsSpanish(mainState.contact__preferred__language,'Continue', 'Continuar')}
			htmlTitle='Personal'
			preRoute='address'
			showPreButton='visible'
			preRouteClass='btn btn-light'
			className=''
			invokeFunction={clickSubmit}
			btnContinueId = 'personalCBtn'
			disabled={disableButton }
			dots = {returnTrueIsSpanishIsPrefered(mainState.contact__preferred__language) ? dotsInSpanish :dots }
		>
			{personalForm()}
		</Layout>
	);
}
