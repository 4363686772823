import React, { useState, useEffect, createContext } from 'react';
import { useHistory } from "react-router-dom";
import TagManager from 'react-gtm-module'
import axios from 'axios';
import config from '../../../../config';
// import uuidv4 from 'uuid/v4';

export const MerchantStateContext = createContext();

export default function MerchantStateContextProvider(props) {
    const [natureOfBusiness, setNatureOfBusiness] = useState([]);
    const [lang, setLang] = useState();
    const [corporateStructures, setCorporateStructures] = useState([]);
    const [data, setData] = useState({
        accountUuid: '',
        contactUuid: '',
        opportunityUuid: '',
        campaign: '',
        campaignVariation: '',
        campaignRecordId: '',
        contactName: '',
        contactEmail: '',
        isLocalHostIP: false,
        publicIp: '',
        opportunityHasSig: false,
        returningUser: false,
        r: '',
    });
    const contexthistory = useHistory();

    const [mainState, setMainState] = useState({
        //----fields collected from app ------------
        contact__workPhone: '',
        contact__address__address1: '',
        contact__address__address2: '',
        contact__address__city: '',
        contact__address__state: '',
        contact__address__zip: '',
        contact__birthDate: '',
        contact__socialSecurityNumber: '',
        contact__creditScoreRatio: '',
        contact__debt: '',
        account__taxId: '',
        opportunity__requestedAmount: '',
        opportunity__revenue: '',
        opportunity__purposeOfFunding: '',
        account__name: '',
        contact__ownership: '',
        account__natureOfBusinessId: '',
        account__natureOfBusiness: '',
        account__natureOfBusinessOther: '',
        account__startDate: '',
        account__address__address1: '',
        account__address__address2: '',
        account__address__city: '',
        account__address__state: '',
        account__address__zip: '',
        account__corporateStructure: '',
        account__dba: '',
        contact__firstName: '',
        contact__lastName: '',
        contact__email: '',
        contact__mobilePhone: '',
        contact__preferred__language: '',
        campaignVariation: '',
        campaignRecordId: ''

    });

    const initializeState = () => {
        let initialState = {
            contact__workPhone: '',
            contact__address__address1: '',
            contact__address__address2: '',
            contact__address__city: '',
            contact__address__state: '',
            contact__address__zip: '',
            contact__birthDate: '',
            contact__socialSecurityNumber: '',
            contact__creditScoreRatio: '',
            contact__debt: '',
            account__taxId: '',
            opportunity__requestedAmount: '',
            opportunity__revenue: '',
            opportunity__purposeOfFunding: '',
            account__name: '',
            contact__ownership: '',
            account__natureOfBusinessId: '',
            account__natureOfBusiness: '',
            account__natureOfBusinessOther: '',
            account__startDate: '',
            account__address__address1: '',
            account__address__address2: '',
            account__address__city: '',
            account__address__state: '',
            account__address__zip: '',
            account__corporateStructure: '',
            account__dba: '',
            contact__firstName: '',
            contact__lastName: '',
            contact__email: '',
            contact__mobilePhone: '',
            campaignVariation: '',
            campaignRecordId: ''

        }

        setMainState(initialState)
    }

    const readNatureOfBusiness = () => {
        return fetch(`${config.apiUrl}/api/uplyftOnboarding/natureOfBusiness`, {
            method: 'GET'
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };

    const readCorporateStructures = () => {
        return fetch(`${config.apiUrl}/api/uplyftOnboarding/corporateStructures`, {
            method: 'GET'
        })
            .then(response => {
                return response.json();
            })
            .catch(err => console.log(err));
    };

    const validatePhoneNumber = async (phoneNumber) => {
        try {
            let validationResponse = await axios.get(`${config.apiUrl}/api/uplyftOnboarding/validatePhoneNumber/${phoneNumber}`, { timeout: 1000 });
            let validationPayload = validationResponse.data;

            return validationPayload;
        } catch (error) {
            // console.error("Error when grabbing onboarding/phonevalidation api endpoint:\n", error)
            return true;
        }
    }

    const validateEmail = async (email) => {
        try {
            const validationResponse = await axios.get(`${config.apiUrl}/api/uplyftOnboarding/validateEmail/${email}`, {timeout: 1000});
            const validationPayload = validationResponse.data;

            return validationPayload;
        } catch (error) {
            // console.error("Error when grabbing onboarding/emailvalidation api endpoint\n", error)
            return true;
        }
    }


    // effect => read nature of business & corporate structures
    useEffect(() => {
        readNatureOfBusiness().then(data => {
            if (data && data.error) {
                console.log(data.error);
            } else {
                setNatureOfBusiness(data);
            }
        });
        readCorporateStructures().then(data => {
            if (data && data.error) {
                console.log(data.error);
            } else {
                /*let reactSelectFormat = [];
                for(let i=0;i<data.length;i++)
                {
                    let formattedObj = {'value': data[i], 'label': data[i]};
                    reactSelectFormat.push(formattedObj);
                }*/
                setCorporateStructures(data);
            }
        });
    },[])

    //get lang
    useEffect(() => { setLang({ is_spanish: false }) },[])

    // Get public IP -> Needs work
	const getPublicIp = () => {
		axios.defaults.withCredentials = false;
		axios.get('https://api.ipify.org/?format=jsonp&callback=getIP').then(res => {
				axios.defaults.withCredentials = true;
				if(res.data && res.data.includes('getIP'))
				{
					res.data = res.data.substring(res.data.indexOf("{"));
					res.data = res.data.substring(0, res.data.indexOf("}")+1);
                    res.data = (JSON.parse(res.data)).ip;


                    // Commented on 17 Nov for allowing the setData to work in all other pages
                    setData({...data, publicIp: res.data })
                    // sessionStorage.setItem("data", JSON.stringify({ ...data, publicIp: res.data })); //this resets the state and remove the campaigns etc.

				}
		})
		.catch(function (error) {
			console.log(error);
		});
	};

    //Native JS sha254
    async function sha256(message) {
        const encoder = new TextEncoder();
        const data = encoder.encode(message)
        const hashBuffer = await crypto.subtle.digest('SHA-256', data) // Perform SHA-256 hash
        const hashArray = Array.from(new Uint8Array(hashBuffer)) // Convert buffer to byte array
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('') // Convert bytes to hex string
        return hashHex;
    }

    function formatDateToYYYYMMDD() {
        return new Date().toLocaleDateString('en-CA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        }).replace(/-/g, '');
    }
    
    
    // effect => get public ip (Currently  does not get used)
    // useEffect(() => { getPublicIp() }, []); //Comented to be tested

    // this function can take a new param from state that will show if this app is new, or an existing merchant. 
    const gtm_DataLayer = async (conRatio, revToCon) => {

        
        let revenue = parseInt(mainState.opportunity__revenue.toString().replace('$','').replace(/,/g,''));
        let reqAmount = parseInt(mainState.opportunity__requestedAmount.toString().replace('$','').replace(/,/g,''));
        let adjustedRevenue = Math.min(15000,revenue*conRatio*revToCon); //Clip max revenue earned at 20k
        let merchantEmail = (mainState.contact__email) ? mainState.contact__email : '';
        // let opportunityUuid = data.opportunityUuid.toString().replace(/-/g, '');
        // let merchantEmailAsOrderId = await sha256(merchantEmail);
        
        //Date in this format MMDDYYYY
        let dateSimplified = formatDateToYYYYMMDD()
        let merchantEmailAsOrderPreSha = dateSimplified+merchantEmail
        let merchantEmailAsOrderId = await sha256(merchantEmailAsOrderPreSha)
        // let adjustedReqAmount = (reqAmount*conRatio*revToCon).toString();
        console.log( 'inside TAGs', 'email:',merchantEmail, 'date:', dateSimplified, 'merged:', merchantEmailAsOrderId) //Order Id Debug
        TagManager.dataLayer({
            dataLayer: {
                event: 'conversion',
                expectedRevenue: adjustedRevenue,
                requestedAmount: reqAmount,
                orderId: merchantEmailAsOrderId,  
                'enhanced_conversion_data': {
                    'email': merchantEmail
                }
            }
        });
    }

    useEffect( data => {
        if (sessionStorage['data'] ){
			let tempDataState = JSON.parse(sessionStorage.getItem("data"));
			setData({ ...data, ...tempDataState })
		}
        //triggering get email to work as needed
                var merchant= sessionStorage['data'];
                var merchantDataString = sessionStorage.getItem("data");
                var merchantEmailString = sessionStorage.getItem("contact__email");
                if(merchantDataString){
                    const parsedData = JSON.parse(merchantDataString);
                    var returningUser = parsedData. returningUser;
                }
       

                //console.log(merchantEmailString);
                //console.log(merchantDataString);
                //console.log(merchant);

            if(sessionStorage['data']===merchantDataString && sessionStorage['data']===merchantEmailString && sessionStorage['data']!==undefined) {


                // window.geq.suppress(); commented for now, 
                console.log("Checks Complete Email is Available");

            }else if(returningUser){

                console.log('This is a returning user', 'this', returningUser)
            
            }else{
                // window.geq.page(); comented for now

                console.log("Checks Complete Email is  not Available");

            }

    },[data.accountUuid, data.contactUuid, data.opportunityUuid, data.campaign, data.campaignVariation]);


    const handleChange = name => event => {
        let eventTarget = '';
        if (name === "account__natureOfBusinessId") {
            eventTarget = event.value
            if (eventTarget !== "" && name !== undefined) {
                let businessNature = natureOfBusiness.find(ele => ele.value === eventTarget)
                setMainState({ ...mainState, [name]: eventTarget, 'account__natureOfBusiness': businessNature.name })
            } else {
                setMainState({ ...mainState, [name]: eventTarget, 'account__natureOfBusiness': "" })
            }
        }
        else if (name === "account__corporateStructure") {
            eventTarget = event.target.value;
            if (eventTarget !== "" && name !== undefined) {
                setMainState({ ...mainState, [name]: eventTarget, 'account__corporateStructure': eventTarget })
            } else {
                setMainState({ ...mainState, [name]: eventTarget, 'account__corporateStructure': "" })
            }
        }
        else {
            eventTarget = event.target.value;
            setMainState({ ...mainState, [name]: eventTarget });
        }
    };

    const addPropertyState = (name, value) => {
        setMainState({ ...mainState, [name]: value });
    }

    const saveAccountData = (account, contact, opportunity) => {

        let accountData = {
            accountUuid:   account.uuid || ''
            , contactUuid:  contact.uuid || ''
            , opportunityUuid:  opportunity.uuid || ''
            , campaign: account.campaign || ''
            , campaignVariation: account.campaignVariation || ''
            , campaignRecordId: account.campaignRecordId || ''
            , contactName:  contact.name || ''
            , contactFirstName:  contact.firstName || ''
            , contactLastName:  contact.lastName || ''
            , contactEmail:  contact.email || ''
            , isLocalHostIP: opportunity.isLocalHostIP || false
            // , isLocalHostIP: false //testing only, 
            , referrer: opportunity.referrer || ''
            , r: opportunity.r || data.r || ''
            , opportunityHasSig: opportunity.userSignature ? true : ''
            , returningUser: true
            
        }

        setData({...data, ...accountData })
        sessionStorage.setItem("data", JSON.stringify(accountData));
    };

    const populateCampaignRecordId = (params) => {
        if(params.gclid && params.gclid != undefined) {
            setMainState({...mainState, 'campaignRecordId': params.gclid})
        }
    };

    const populateCampaignVariation = (params) => {

        console.log('params MSC', params)
        if(params.campaignVariation !== undefined && params.campaignVariation)
        {
            setMainState({...mainState, 'campaignVariation': params.campaignVariation});
        }
        if(params.utm_campaignVariation !== undefined && params.utm_campaignVariation)
        {
            setMainState({...mainState, 'campaignVariation': params.utm_campaignVariation});
        }
    };

    const prePopulateMainState = (params) => {
        sessionStorage.clear();
        axios.defaults.withCredentials = true;
        axios.get(`${config.apiUrl}/api/uplyftOnboarding/index/${params.ac}/${params.co}/${params.op}?campaign=${params.campaign}&r=${params.r}` )
            .then( res => {
                const {account, contact, opportunity} = res.data;
                if(account.done !== undefined && account.done)
                {
                    contexthistory.push(`/onboarding/merchant/finished?s=2`);
                    console.error("Already completed application.");
                }
                else
                {
                    saveAccountData(account, contact, opportunity);
                    // var email= account.email;
                    // console.log(email);
                    // if(sessionStorage['data']===email&& sessionStorage.getItem("contact__email")===account.email)
                    // {
                    //    return window.geq.suppress();
                    // }else{
                    //    return window.geq.page();
                    // }
                    setMainState({
                        ...mainState
                        , 'contact__workPhone': contact.workPhone || ""
                        , 'contact__address__address1': contact.address.address1 || ""
                        , 'contact__address__address2': contact.address.address1 || ""
                        , 'contact__address__city': contact.address.city || ""
                        , 'contact__address__state': contact.address.state || ""
                        , 'contact__address__zip': contact.address.zip || ""
                        , 'contact__birthDate': contact.birthDate || ""
                        , 'contact__socialSecurityNumber': contact.socialSecurityNumber || ""
                        , 'contact__preferred__language': contact.preferred_language || "English"
                        , 'account__taxId': account.taxId || ""
                        , 'opportunity__requestedAmount': opportunity.requestedAmount || ""
                        , 'opportunity__revenue': opportunity.monthlyRevenue || ""
                        , 'opportunity__purposeOfFunding': opportunity.purposeOfFunding || ""
                        , 'account__name': account.name || ""
                        , 'contact__ownership': contact.ownership || ""
                        , 'account__natureOfBusinessId':  account.natureOfBusinessId || ""
                        , 'account__natureOfBusiness': account.natureOfBusiness || ""
                        , 'account__natureOfBusinessOther': account.natureofBusinessOther || ""
                        , 'account__startDate': account.startDate.substring(0,10) || ""
                        , 'account__address__address1': account.address.address1 || ""
                        , 'account__address__address2': account.address.address2 || ""
                        , 'account__address__city': account.address.city || ""
                        , 'account__address__state': account.address.state || ""
                        , 'account__address__zip': account.address.zip || ""
                        , 'account__workPhone': account.phone || ""
                        , 'account__corporateStructure': account.corporateStructure
                        , 'account__dba': account.dba
                        , 'contact__firstName': contact.firstName || ""
                        , 'contact__lastName': contact.lastName || ""
                        , 'contact__email': contact.email || ""
                        , 'contact__mobilePhone': contact.mobilePhone || ""
                        , 'urgency': opportunity.urgency
                        , 'minimum_requested_amount': opportunity.minimum_requested_amount
                        , 'use_of_proceeds_description': opportunity.use_of_proceeds_description
                        , 'requested_preference': opportunity.requested_preference
                        , 'preferred_contact_time': contact.preferred_contact_time
                        , 'preferred_contact_timeZone': contact.preferred_contact_timeZone
                        , 'preferred_contact_method': contact.preferred_contact_method
                        , 'url': account.website
                        , 'online_presence_instagram': (account.online_presence && account.online_presence.instagram) ? account.online_presence.instagram : ""
                        , 'online_presence_facebook': (account.online_presence && account.online_presence.facebook) ? account.online_presence.facebook : ""
                        , 'online_presence_yelp': (account.online_presence && account.online_presence.yelp) ? account.online_presence.yelp : ""
                        , 'opportunity__uuid': opportunity.uuid || ''
                        , 'account__uuid': account.uuid || ''
                        , 'contact__uuid': contact.uuid || ''

                    });
                }
            })
            .catch( err => {
                setData({...data, campaignVariation: '404prePopulate' });
                sessionStorage.setItem("data", JSON.stringify({ ...data, campaignVariation: '404prePopulate' }));
                console.error('prePopulate:', err);
            });
    };

    return (
        <MerchantStateContext.Provider value={{setMainState, setData, mainState, handleChange, prePopulateMainState, data, addPropertyState, initializeState, natureOfBusiness, corporateStructures, gtm_DataLayer, populateCampaignVariation, lang, setLang, validatePhoneNumber, validateEmail, populateCampaignRecordId}}>
            {props.children}
        </MerchantStateContext.Provider>
    )
}
