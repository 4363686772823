import React, { Component } from 'react';
// import { connect} from 'react-redux';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileMetadata, FilePondPluginFileValidateSize);

class UploadItemComponent extends Component {

	constructor(props) {
        super(props);

        this.state = {
            files: []
		}; 
    }
    
    async componentDidMount(){
        this.setState({
            files: []
        });
    }

    removeFile = async (fileKeyObject) => {
        let fileKey = JSON.parse(fileKeyObject);
        await this.props.removeFile(fileKey.key);
    }

    handleInit() {
        // console.log('FilePond instance has initialised', this.pond);
    }

    render(){
        let these = this;
		return(
            <FilePond ref={ref => this.pond = ref}
                files={this.state.files}
                allowMultiple={true}
                maxFiles={1}
                instantUpload= {true}
                allowFileMetadata = {true}
                fileMetadataObject = {{ message: 'include some metadata here'}}
                //File Size Validation
                allowFileSizeValidation = {true}
                maxFileSize = {'5MB'}
                maxTotalFileSize = {'5MB'}
                labelMaxFileSizeExceeded = {'File is too large'}
                labelMaxFileSize = {'Maximum file size is {filesize}'}
                labelMaxTotalFileSizeExceeded = {'Maximum total size exceeded'}
                labelMaxTotalFileSize = {'Maximum total file size is {filesize}'}
                credits = {false}
                server= {{
                    timeout: 7000
                    , process:(fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
            
                        // fieldName is the name of the input field
                        // file is the actual file object to send
                        const formData = new FormData();
                        formData.append(fieldName, file, file.name);
            
                        const request = new XMLHttpRequest();
                        request.open('POST', these.props.url);
            
                        // Should call the progress method to update the progress to 100% before calling load
                        // Setting computable to false switches the loading indicator to infinite mode
                        request.upload.onprogress = (e) => {
                            progress(e.lengthComputable, e.loaded, e.total);
                        };
                        // Should call the load method when done and pass the returned server file id
                        // this server file id is then used later on when reverting or restoring a file
                        // so your server knows which file to return without exposing that info to the client
                        request.onload = function() {
                            if (request.status >= 200 && request.status < 300) {
                                // the load method accepts either a string (id) or an object
                                load(request.responseText);
                                // these.props.updateRequiredDocsState(JSON.parse(request.responseText).key, 'loaded');
                                if(these.props.afterRemoveFile){
                                    these.props.afterRemoveFile(JSON.parse(request.responseText).key, 'loaded');
                                }
                            }
                            else {
                                // Can call the error method if something is wrong, should exit after
                                error('oh no');
                            }
                        };
            
                        request.send(formData);
                        
                        // Should expose an abort method so the request can be cancelled
                        return {
                            abort: () => {
                                // This function is entered if the user has tapped the cancel button
                                request.abort();
            
                                // Let FilePond know the request has been cancelled
                                abort();
                            }
                        };
                    },
                    revert: (fileKey, load, error) => {

                        these.props.onRemoveFile(fileKey);
                        if(these.props.afterRemoveFile){
                            these.props.afterRemoveFile(JSON.parse(fileKey).key, 'removed');
                        }
                        // Should remove the earlier created temp file here
                        // ...
            
                        // Can call the error method if something is wrong, should exit after
                        error('oh my goodness');
            
                        // Should call the load method when done, no parameters required
                        load();
                    }
                }}

                oninit={() => this.handleInit() }
                // labelIdle= {'<span class="text-purple"> <i class="fa fa-cloud-upload fa-4x "></i>' + "  "+ these.props.itemName + ' </span>'}
                labelIdle= {'<i class="fa fa-cloud-upload text-purple fa-5x upload-label" ></i> <span></span>'}
                onupdatefiles={fileItems => {
                    // Set currently active file objects to this.state
                    this.setState({
                        files: fileItems.map(fileItem => fileItem.file)
                    });
                }}>
            </FilePond> 
		);
	}
}

//PropTypes
UploadItemComponent.propTypes = {
}


export default UploadItemComponent;